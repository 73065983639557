import logo from './logo.svg';
import './App.css';
import  React,{useState} from 'react';

import Login from './components/Login';
import Manager from './components/Manager';

import './config.js';
function App() {
  console.log("global:",global.constrants.showloginboo);
  const [title,setTitle] = useState('logostr');
  const [showlogin,setisshowlogin]=useState(global.constrants.showloginboo);
  const login_call_back_str=(values)=>{
    const{username,password}=values;
    if(username=='12' && password=='1212'){
      global.constrants.showloginboo=false;
      setisshowlogin(false);
    }
  }
  return (
    <div className="App">
      {
        showlogin
        ?
        ( 
          <div style={{width:'500px',marginTop:'200px',margin:'0 auto'}}>
            <div>建设中</div>
              {/* <Login 
              title={title}
              login_call_back_str_children={login_call_back_str}
              /> */}
          </div>
        )
        :
        (
          <Manager/>
        )
      }
    </div>
  );
}

export default App;
