import React from 'react';
import { Layout, Menu, Breadcrumb} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined,FundFilled } from '@ant-design/icons';
import { NavLink,Link,Routes, Route,Navigate} from 'react-router-dom';
import { nanoid } from 'nanoid';

import User from '../User';

const { Header, Content, Sider } = Layout;
// const layout_array_header=[''];
// const layout_array_left_side_main=array(array('功能1',array('功能1-1','功能1-2','功能1-3','功能1-4')),array('功能2',array('功能2-1','功能2-2','功能2-3','功能2-4')));
const { SubMenu } = Menu;
export default () => (
  <Layout>
    <Header className="header">
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key={nanoid()}>菜单1</Menu.Item>
          <Menu.Item key={nanoid()}>菜单2</Menu.Item>
          <Menu.Item key={nanoid()}>菜单3</Menu.Item>
      </Menu>
      </Header>
      <Layout>
      <Sider width={200} className="site-layout-background">
          <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          >
          <SubMenu key={nanoid()} icon={<FundFilled />} title="资料管理">
              <SubMenu key={nanoid()} icon={<FundFilled />} title="客户管理">
              {/* <Menu.Item key={nanoid()}><Link to="client_add">客户添加</Link></Menu.Item>
              <Menu.Item key={nanoid()}><Link to="client_update">客户修改</Link></Menu.Item>
              <Menu.Item key={nanoid()}><Link to="client_delete">客户删除</Link></Menu.Item> */}
                  {/* <Menu.Item key={nanoid()}><Link to="watersrearch_group">水表分组管理</Link></Menu.Item> */}
              </SubMenu>
              <SubMenu key={nanoid()} icon={<FundFilled />} title="工程管理">
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch">电表管理</Link></Menu.Item> */}
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch_group">电表分组管理</Link></Menu.Item> */}
              </SubMenu>
              <SubMenu key={nanoid()} icon={<FundFilled />} title="人员管理">
                  <Menu.Item key={nanoid()}><Link to="/user">人员添加</Link></Menu.Item>
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch_group">电表分组管理</Link></Menu.Item> */}
              </SubMenu>
              <SubMenu key={nanoid()} icon={<FundFilled />} title="plc地址管理">
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch">电表管理</Link></Menu.Item> */}
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch_group">电表分组管理</Link></Menu.Item> */}
              </SubMenu>
              <SubMenu key={nanoid()} icon={<FundFilled />} title="盒子管理">
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch">电表管理</Link></Menu.Item> */}
                  {/* <Menu.Item key={nanoid()}><Link to="powersrearch_group">电表分组管理</Link></Menu.Item> */}
              </SubMenu>
          </SubMenu>
          <SubMenu key={nanoid()} icon={<LaptopOutlined />} title="区域管理">
              <Menu.Item key={nanoid()}>检修区</Menu.Item>
              <Menu.Item key={nanoid()}>卫生区</Menu.Item>
              <Menu.Item key={nanoid()}>绿化区</Menu.Item>
          </SubMenu>
          <SubMenu key={nanoid()} icon={<UserOutlined />} title="行政管理">
              {/* <Menu.Item key={nanoid()}><Link to="company_manager">公司管理</Link></Menu.Item> */}
              <Menu.Item key={nanoid()}>人员管理</Menu.Item>
          </SubMenu>
          <SubMenu key={nanoid()} icon={<LaptopOutlined />} title="设备管理">
              <Menu.Item key={nanoid()}>添加设备</Menu.Item>
              <Menu.Item key={nanoid()}>待定</Menu.Item>
              <Menu.Item key={nanoid()}>待定</Menu.Item>
          </SubMenu>
          </Menu>
      </Sider>
      <Layout
        style={{
          padding: '0 24px 24px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
        <div style={{width:500,}}>
          <Routes>
              <Route path="/user" element={<User/>}/>
          </Routes>
        </div>
          
      </Layout>
    </Layout>
  </Layout>
);
